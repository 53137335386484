.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3vw;
}

.contact-content {
  display: flex;
  width: 100%;
  max-width: 1200px;
}

.contact-left {
  flex: 1;
  padding: 2vh;
  height: 30vw;
  margin-bottom: 4vh;
}

.contact-right {
  flex: 1;
  padding: 20px;
  background-color: #0056b3;
  border-left: 1px solid #ddd;
  display: flex;
  flex-direction: column;
 
}

.branch-selector {
  margin-bottom: 20px;
  text-align: center;
}

.branch-button {
  margin: 0 10px 10px 0;
  padding: 10px 20px;
  border: none;
  background-color: white;
  color:#007bff ;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.branch-button.active {
  background-color: #0056b3;
  color: white;
}

.branch-button:hover {
  background-color: #0056b3;
 
}

.contact-right-head {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: white;
}

.Clinic-Name {
  font-size: 1.5rem;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-right-para {
  font-size: 1rem;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.copyright-section {
  margin-top: 1vw;
  text-align: center;
  width: 100%;
  color: white;
  padding: 1vw;
  background-color: #0056b3;
}   

.marketingagency {
  color: #007bff;
  text-decoration: none;
  color: white;
}

.marketingagency:hover {
  text-decoration: underline;
}
.branchhead{
  font-size: 2rem;
  font-weight: bold;
  color: #0056b3;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@media screen and (max-width: 991px) {
  .contact-content {
    flex-direction: column;
  }

  .contact-left, .contact-right {
    padding: 1vh !important;
    width: 100%;
    margin-bottom: 0 !important;
  }

  .contact-left {
    height: 30vh !important; /* Adjust height as needed */
  }
  
.contact-right-head {
  font-size: 1rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: white;
}

.Clinic-Name {
  font-size: 1rem;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-right-para {
  font-size: 0.7rem;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}
}