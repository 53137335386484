.certificate-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .certificate-container-p {
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: bold;
    color: #0056b3;
    padding: 3vw;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .certificate-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  
  .certificate-list img {
    width: 25vw;
    height: auto;
    border: 2px solid #ccc;
    border-radius: 5px;
    transition: transform 0.3s ease;
  }
  
  .certificate-list img:hover {
    transform: scale(1.20);
  }
  
  /* Keyframe animation for certificates */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .certificate-list img {
    animation: fadeIn 0.5s ease-in-out;
  }
  