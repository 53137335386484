body {
 
  font-family: Arial, sans-serif;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-4px); }
  50% { transform: translateX(4px); }
  75% { transform: translateX(-4px); }
  100% { transform: translateX(0); }
}

.shake-animation {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.5rem;
  background-color: #df3412;
  color: #fff;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  width:15vw;
  animation: shake 0.7s infinite;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
  position: fixed;
  top: 0;

  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.logo img {
    height: 5vw;
    margin-left: 3vw;
}


.wp-button{
  background-color: rgb(10, 237, 10);
  color: white;
  border-radius: 1vw;
  border: none;
  padding: 0.5vw;
  margin-right: 3vw;
  padding: 0.5vw;
  width: 15vw;
  font-size: 1.5rem;
  animation: shake 0.7s infinite;
}

.contact-info {
    margin-right: 10vw;
    background-color: rgb(223, 25, 25);
    padding: 0.6vw;
    border-radius: 1vw;
    border: none;
    color: white;
    padding: 0.5vw;
  
}
.phone-icon {
    color: white;
    padding: 1vh;
    font-size: 3rem;
}

.hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vw;
    width: 100%;
    background: url('./images/young-couple-has-difficult-problem-bed.jpg') no-repeat center center/cover;
}

.hero-content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.hero-text {
    width: 50%;
    color: rgb(14, 77, 144);
    text-align: left;
}

.hero-text-head {
    margin: 0;
    font-weight: bold;
    font-size: 2rem;
}

.hero-text-para {
    margin: 20px 0;
    font-weight: bold;
    font-size: 1rem;
}

.hero-form {
    width: 50%;
    padding: 3vw;
    margin-top: 10vw;
    background-color:  rgba(0, 0, 0, 0.525) !important;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.form-head{
  color: white;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}
.form-para{
  text-align: center;
  color: white;
}
.confidential{
      text-align: center ;
      font-weight: bold;
      color: white;
}
.consultation-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: white;
}

.consultation-form input,
.consultation-form textarea {

    width: 100%;
    padding: 1vw;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.consultation-form-button {
    background-color: #c21910 !important;
    margin-left: 15vw;
    color: #fff;
    border: none;
    padding: 1vh;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.consultation-form button:hover {
    background-color: #004050;
}

.welcome {
    display: flex;
    justify-content: space-between;
    margin-left: 10vw;
    margin-right: 10vw;
    align-items: flex-start;
     
}
.Doctor-info {
  flex: 1;
  background-color:#4394e6; /* Light blue background for Doctor-info */
  color: white;
  padding: 3vw;
}
.Doctor-photo {
  flex: 1;
  text-align: center;
}
.Doctor-photo img {
  max-width: 30vw;
  height: 38vw;
  object-fit: cover;

}
ul {
  list-style-type: disc;
 
}

li {
  margin-bottom: 5px;
  text-align: left;
}

.welcome-button {
    background-color: #005f7f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.welcome-button:hover {
    background-color: #004050;
}

.header.white {
  background-color: white;
}

.logo img {
  height: 5vw;
  margin-left: 3vw;
}

.contact-info {
  margin-right: 10vw;
  background-color: rgb(223, 25, 25);
  padding: 0.6vw;
  border-radius: 1vw;
}

.phone-icon {
  color: white;
}
.hero-text-head {
  margin: 0;
  font-weight: bold;
  font-size: 3rem;
}

.hero-text-para {
  margin: 20px 0;
  font-weight: bold;
  font-size: 1rem;
}

.hero button {
  background-color: #045e7c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.welcome-h {
  color: #045e7c;
  font-weight: bold;
  font-size: 2rem;
 
  margin-top: 2vw;
  text-align: center;
}

.welcome-head {
  margin-top: 0;
  font-size: 2rem;
  font-weight: bold;
  color: #045e7c;

  text-align: center;
 
}

.welcome-subhead {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  
}

.welcome-para {
  font-size: 1rem;
  line-height: 2vw;
  text-align: left;
}

.welcome-button {
  background-color:white;
  color:  #008cba;
  border: none;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
}


.Media {
  width: 80%;
  margin-left: 15vw;
}

.media-head {
  font-size: 2rem;
  color:#106987;
  font-weight: bold;
  text-align: center;
  padding: 3vw;
}

/* Media Queries */
@media screen and (max-width: 991px) {
 
  .logo img {
    height: 10vw;
    margin-left: 2vw;
  }

  .header {
    flex-direction: column;
    background-color: rgb(45, 44, 44); /* Dark Gray */
    transition: background-color 0.3s ease;
   
  }
  .head{
    margin-top: 2vh;
  
  }
  .shake-animation {
    display: inline-block;
    padding: 0.5vh !important;
    font-size: 0.7rem;
    background-color: #df3412;
    color: #fff;
    border: none;
    margin-left: 2vh;
    border-radius: 1vw;
    width: 15vh;
    cursor: pointer;
    padding-right: 2vh !important;
    animation: shake 0.7s infinite;
  }
  .wp-button {
    margin-right: 2vw;
    padding-right: 2vh;
    font-size: 0.8rem;
    width: 15vh;
  }
  .phone-icon{
    font-size: 1.8rem;
  }
  .hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 13vh;
    height: 50vh;
    background: url('./images/young-couple-has-difficult-problem-bed.jpg') no-repeat center center/cover;
}
  .hero {
    height: 80vw;
  }
  

  .hero-content {
    flex-direction: column;
    width: 90%;
  }

  .hero-text {
    width: 100%;
    text-align: center;
    margin-top: 80vh;
  }
.hero-text-head {
    margin: 0;
    font-weight: bold;
    font-size: 1.5rem;
}

.hero-text-para {
    margin: 20px 0;
    font-weight: bold;
    font-size: 0.7rem;
 }
  .hero-form {
    width: 90%;
    padding: 10px;
    margin-top: 10vh;

  }

  .form-head{
    font-size: 1rem;
  } 
  .form-para, .confidential {
    font-size: 0.7rem;
  
  }
  .consultation-form label{
    font-size: 0.5rem;
  }
  .consultation-form input, .consultation-form textarea {
    padding: 0.5vh;
  }

  .consultation-form-button {
    margin-left: 0;
    width: 100%;
    padding: 1.5vh;
    font-size: 0.5rem;
  }
  .Doctor-info {
    flex: 1;
    background-color:rgb(14, 94, 185); 
    color: white;
    padding: 3vw;
  }
  
.welcome-h {
  color: rgb(14, 94, 185);
  font-weight: bold;
  font-size: 1rem;
 
  margin-top: 66vh;
  text-align: center;
}

.welcome-head {
  margin-top: 0;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(14, 94, 185);

  text-align: center;
 
}

.welcome-subhead {
  font-size: 0.7rem;
  font-weight: bold;
  text-align: left;
  
}

.welcome-para {
  font-size: 0.7rem;
  line-height: 2vh;
  text-align: left;
}
.Doctor-info ul{
  font-size: 0.6rem;
}
.welcome-button {
  background-color: #eef0f0;
  color: rgb(14, 94, 185);
  border: none;
  width: 20vh !important;
  font-size: 1rem !important;
  font-weight: bold;
  cursor: pointer;
}


  .Doctor-photo {
    flex: 1;
    text-align: center;
  }
  .Doctor-photo img {
    max-width: 100%;
    height: 35vh;
  }
  ul {
    list-style-type: disc;
   
  }
  
  li {
    margin-bottom: 5px;
    text-align: left;
  }
  .welcome {
    flex-direction: column;
    padding: 20px 10px;
  }

  .Doctor-info, .Doctor-photo {
    flex: 100%;
    text-align: center;
    padding: 2vw;
  }

  .welcome-button {
    width: 100%;
    padding: 1.5vh;
    font-size: 1rem;
  }

  .Media {
    width: 60% !important;

  }

  .media-head {
    font-size: 1.5rem;
  }
}